.site-width {
  max-width: 1200px;
  margin: auto;
  text-align: center;
}

.main-loading {
  height: 100vh;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}